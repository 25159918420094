<template>
  <div v-if="hasPerm('youth.view_seanceinscription')">
    <page-header title="Vérification du pointage" icon="fas fa-clock"></page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <b-row>
      <b-col>
        <div v-if="items.length === 0 && !isLoading(loadingName)" class="empty-text">
          Tous les pointages ont été effectués
        </div>
        <table class="table table-striped table-bordered" v-if="items.length && !isLoading(loadingName)">
          <tr>
            <th>Date</th>
            <th>{{ youthHomeLabel }}</th>
            <th>Type</th>
            <th>Période</th>
            <th class="text-right">À pointer</th>
          </tr>
          <tr v-for="item of items" :key="item.key">
            <td>{{ item.date|dateToString('dddd LL') }}</td>
            <td>{{ item.youth_home.name }}</td>
            <td>{{ item.seance_type.name }}</td>
            <td>{{ item.period.name }}</td>
            <td class="text-right">
              <router-link class="btn btn-secondary" :to="getLinkToInscriptions(item)">{{ item.count }}</router-link>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import store from '@/store'

export default {
  name: 'youth-homes',
  mixins: [BackendMixin],
  components: {
    LoadingGif,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      loadingName: 'checking-clocking',
      items: [],
    }
  },
  watch: {
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
  },
  mounted() {
    this.loadChecking()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadChecking() {
      this.startLoading(this.loadingName)
      const url = '/api/clocking-checking/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.items = resp.data
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    getLinkToInscriptions(item) {
      let data = {
        name: 'youth-homes-day',
        params: {
          day: dateToString(item.date, 'YYYY-MM-DD'),
          youthHome: '' + item.youth_home.id,
          seanceType: '' + item.seance_type.id,
          seancePeriod: '' + item.period.id,
        },
      }
      return data
    },
  },
}
</script>

<style lang="less">

</style>
